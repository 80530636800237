import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { map as _map, find as _find } from 'lodash';
import chapters from '@config/chapters';
import ChapterItem from '@components/roadmap/chapter-item';

import './chapters-list.scss';
import {useTranslation} from "gatsby-plugin-react-i18next";

const COMPONENT_CLASS = 'chaptersList';
const QUERY_GET_ALL_ICONS = graphql`
query{
    allFile(filter: {extension: {eq: "svg"}, relativeDirectory: {eq: "icons"}}) {
        edges {
            node {
                name
                publicURL
            }
        }
    }
}`;

const ChaptersList = ({ className, itemClass }) => {
    const { t } = useTranslation();
    const queryData = useStaticQuery(QUERY_GET_ALL_ICONS);
    const icons = _map(queryData?.allFile?.edges, edge => {
        return edge?.node || {};
    });
    const chaptersWithIcons = _map(chapters, (chapter) => {
        const icon = _find(icons, icon => icon.name === chapter.icon);
        const { id, title, description } = chapter;
        const chapterData = {
            id,
            title: t(title),
            description: t(description),
            icon: {icon: chapter.icon, ...icon}
        };

        const itemClasses = classNames(`${COMPONENT_CLASS}__item`, itemClass);

        return (<div key={chapter.id} className={itemClasses}>
                    <ChapterItem data={chapterData} stretchedLink />
                </div>)

    })
    const classes = classNames(COMPONENT_CLASS, className);

    return (
        <section className={classes}>
            {chaptersWithIcons}
        </section>
    );
};


ChaptersList.propTypes = {
    className: PropTypes.string,
    itemClass: PropTypes.string,
};

ChaptersList.defaultProps = {
    className: '',
    itemClass: '',
};

export default ChaptersList;
