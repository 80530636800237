import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link, Trans, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import {
    map as _map,
} from 'lodash';
import sanitizeUrl from '@utils/sanitize-url';

import './steps.scss';

const COMPONENT_CLASS = 'stepsNumbers';

const Steps = ({ items, currentStep, onClick, readonly, showDescription }) => {
    const { t } = useTranslation();
    const { originalPath } = useI18next();
    const currentStepItem = items[currentStep];
    const currentTitle = (currentStepItem && currentStepItem.title) || currentStepItem;

    const classes = classNames('row', COMPONENT_CLASS, readonly ? `${COMPONENT_CLASS}--readonly` : null);
    const onItemClick = (event, key) => {
        if(readonly) {
            event.preventDefault();
            return false;
        }

        if(onClick) {
            onClick(key);
        }
    };

    return (
        <div className="container">
            <div className={classes}>
                {
                    _map(items, (item, key) => {
                        const stepIsActive = key === currentStep;
                        const title = t(item.title || item);
                        const description = item.description || '';
                        const growClasses = readonly ? '' : 'flex-grow-0 flex-md-grow-1'
                        const lastClass = key === items.length - 1 ? `stepsNumbers__step--last ${growClasses}`: null;

                        const stepClasses = classNames('col stepsNumbers__step', lastClass);
                        const numberClasses = classNames('stepsNumbers__number stretched-link text-center', stepIsActive ? 'stepsNumbers__number--active' : null);
                        const titleClasses = classNames('stepsNumbers__title', readonly ? '' : 'd-none d-md-block', stepIsActive ? 'stepsNumbers__title--active' : null);
                        const to = readonly ? '#' : `${originalPath}#${sanitizeUrl(title)}`;

                        return (
                            <div className={stepClasses} key={key}>
                                <Link
                                    to={to}
                                    onClick={(event) => onItemClick(event, key)}
                                    className={numberClasses}
                                >{ key + 1 }</Link>
                                <h3 className={titleClasses}><Trans>{ title }</Trans></h3>
                                { showDescription ?
                                    (<p className={`${COMPONENT_CLASS}__description`}><Trans>{description}</Trans></p>) : null
                                }
                            </div>
                        );
                    })
                }
                <div className="col-12 stepsNumber--mobile d-md-none">
                    <h3 className="stepsNumbers__title"><Trans>{ currentTitle }</Trans></h3>
                </div>
            </div>
        </div>
    );
};


Steps.propTypes = {
    items: PropTypes.array.isRequired,
    currentStep: PropTypes.number,
    onClick: PropTypes.func,
    readonly: PropTypes.bool,
    showDescription: PropTypes.bool,
};

Steps.defaultProps = {
    currentStep: null,
    onClick: () => {},
    readonly: false,
    showDescription: false,
};


export default Steps;
