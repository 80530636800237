import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Box from '@molecules/box';

import './chapter-item.scss';

const COMPONENT_CLASS = 'chapterItem';

const ChapterItem = ({ data, stretchedLink }) => {
    const { t, i18n } = useTranslation();
    const { id, title, description } = data;
    const url = i18n.language === 'en' ? `/chapters/chapter-${id}` : `/chapitres/chapitre-${id}`;
    const icon = data?.icon?.publicURL;
    const subtitle = `${t('Chapter')} ${id}`;

    return (
        <article className={COMPONENT_CLASS}>
            <Box
                icon={icon}
                title={t(`chapters:${title}`)}
                subtitle={subtitle}
                description={t(`chapters:${description}`)}
                buttonUrl={url}
                stretchedLink={stretchedLink}
                >

            </Box>
        </article>
    );
};

ChapterItem.propTypes = {
    data: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    stretchedLink: PropTypes.bool,
};

ChapterItem.defaultProps = {
    onClick: () => {},
    stretchedLink: false,
};


export default ChapterItem;
