/* Titles and descriptions come from translation files */

const chapters = [
    {
        id: 1,
        title: `What's next?`,
        description: `Being mindful about your 'new normal'`,
        icon: 'basic-signs',
        url: '',

    },
    {
        id: 2,
        title: 'What to expect after treatment',
        description: 'Managing side effects and their symptoms',
        icon: 'eye',
        url: '',
    },
    {
        id: 3,
        title: 'Emotions, fears and relationships',
        description: 'Managing your emotional  health',
        icon: 'heart',
        url: '',
    },
    {
        id: 4,
        title: 'Regaining function',
        description: 'Promoting and maintaining health',
        icon: 'activity',
        url: '',
    },
    {
        id: 5,
        title: 'Back to work',
        description: 'Planning your return',
        icon: 'briefcase',
        url: '',
    },
    {
        id: 6,
        title: 'Family caregiver support',
        description: 'Taking care of the caregiver',
        icon: 'users',
        url: '',
    },
    {
        id: 7,
        title: 'Where to get reliable health information',
        description: 'How to search, how to evaluate',
        icon: 'info',
        url: '',
    },
];


export default chapters;
