import React, { useEffect } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { map as _map } from 'lodash';

import Layout from "@components/layout"
import SEO from "@components/seo"
import { Hero, HERO_DESCRIPTION_RIGHT } from "@molecules/hero"
import Section from '@atoms/section';
import Subtitle from '@atoms/subtitle';
import Text from '@atoms/text';
import { Button } from '@atoms/button';
import ChaptersList from '@organisms/chapters-list';
import About from '@organisms/about';
import questionnaireSteps from '@config/questionnaire';
import Steps from '@components/roadmap/steps';
import moveToElement from '@utils/move-to-element';

import './index.scss';

const IndexPage = () => {
  const { t } = useTranslation();

  const titles = _map(questionnaireSteps, step => {
    const {title, description} = step;

    return {
      title,
      description,
    }
  });

  useEffect(() => {
    moveToElement(false);
  });

  return (
    <Layout className="home">
      <SEO title={t('index:title')} />
      <Hero
            title="index:heroTitle"
            description="index:heroDescription"
            descriptionPosition={HERO_DESCRIPTION_RIGHT}
            meshGradient
        />
        <section className="secondary-section section padding">
          <Section className="mt-0">
            <Subtitle text="iyn:title" />
            <Text size="60">index:iynDescription</Text>
            <Text size="60">index:iynDescription2</Text>
          </Section>
          <Section>
            <Subtitle text="All Chapters" />
            <ChaptersList className="row" itemClass="col-md-6 col-lg-4" />
          </Section>
          <Section className="overflow-hidden mb-0">
            <Subtitle text="index:questionnaireTitle" />
              <Text size="60">index:questionnaireDescription</Text>
            <div className="stepsContainer">
              <Steps items={titles} type="home" readonly={true} showDescription={false} />
            </div>
          </Section>
          <Section className="mt-6">
            <div className="d-flex flex-row justify-content-end">
              <Button to="/identify-your-needs">Start Questionnaire</Button>
            </div>
          </Section>
          <About />
      </section>
    </Layout>
  )
};

export default IndexPage
