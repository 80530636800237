const moveToElement = (currentState, saveState, callback, timeout = 300) => {
    if (currentState) {
        return;
    }

    setTimeout(() => {
        const hash = window.location.href.indexOf('#')
        const originUrl = `${window.location.origin}${window.location.pathname}#`;
        const currentPath = window.location.href.replace(originUrl, '');

        if (!currentPath || hash < 0) {
            if (typeof callback === 'function') {
                callback(null);
            }
            return;
        }

        const element = document.getElementById(currentPath);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });

            if (typeof callback === 'function') {
                callback(element, hash);
            }
        }
    }, timeout);
    if (typeof saveState === 'function') {
        saveState(true);
    }
};

export default moveToElement;
