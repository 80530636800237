import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { map as _map } from 'lodash';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Section from '@atoms/section';
import Subtitle, {SUBTITLE_SIZE_MEDIUM, SUBTITLE_STYLE_BORDER_BOTTOM} from '@atoms/subtitle';
import Text from '@atoms/text';
import { Button, BUTTON_TYPE_GRADIENT_LINK, BUTTON_TYPE_RIGHT_ARROW } from '@atoms/button';
import AboutLogos from "@organisms/about-logos";
import titleIcon from '@images/inline-icons/basic-signs.svg';

import './about.scss';

const COMPONENT_CLASS = 'about';

export const aboutLinks = [
    {
        text: 'about:buttonAboutUs',
        to: '/about-us',
    },
    {
        text: 'about:buttonAcknowledgements',
        to: '/about-us',
        hash: 'about:buttonAcknowledgementsHash',
    },
    {
        text: 'about:buttonMeetExperts',
        to: '/about-us',
        hash: 'about:buttonMeetExpertsHash',
    }
];

const About = ({ className, hideMenu }) => {
    const { t } = useTranslation();
    const classes = classNames(COMPONENT_CLASS, className);
    const descriptionClass = hideMenu ? 'col-12' : 'col-md-6 col-lg-7';

    return (
        <Section className={classes}>
            <Subtitle size={SUBTITLE_SIZE_MEDIUM} style={SUBTITLE_STYLE_BORDER_BOTTOM} text="about:title" icon={titleIcon} />
            <div className="row">
                <div className={`${descriptionClass}`}>
                    <Text>about:description</Text>
                </div>
                {
                    !hideMenu ?
                        (
                            <div className="col-md-6 col-lg-5">
                                <ul className={`${COMPONENT_CLASS}__links list-unstyled`}>
                                    {
                                        _map(aboutLinks, (link, key) => {
                                            const { to, text, hash } = link;
                                            const href = `${to}${t(hash)}`;
                                            const styles = [BUTTON_TYPE_GRADIENT_LINK, BUTTON_TYPE_RIGHT_ARROW];
                                            return (
                                                <li key={key}>
                                                    <Button to={href} type={styles}>{text}</Button>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                            </div>
                        ) : null
                }
            </div>
            <AboutLogos />
        </Section>
    );
};


About.propTypes = {
    className: PropTypes.string,
    hideMenu: PropTypes.bool,
};

About.defaultProps = {
    className: '',
    hideMenu: false,
};

export default About;
