import React from 'react';
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import sanitizeUrl from '@utils/sanitize-url';

import './subtitle.scss';

const COMPONENT_CLASS = 'subtitle';
export const SUBTITLE_SIZE_MEDIUM = 'medium';
export const SUBTITLE_STYLE_BORDER_BOTTOM = 'border-bottom';

const Subtitle = ({ children, text, className, size, style, icon }) => {
    const { t } = useTranslation();
    const classes = classNames(
        COMPONENT_CLASS,
        'font-weight-bold',
        className,
        size ? `${COMPONENT_CLASS}--${size}` : null,
        style ? `${COMPONENT_CLASS}--${style}` : null,
        {
            'icon' : icon
        }
    );
    const id = sanitizeUrl(t(text));

    return (
        <h2 className={classes} id={id}>
            {
                icon && <img src={icon} alt="" />
            }
            <Trans>{text}</Trans>
            {children}
        </h2>
    );
};


Subtitle.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.string,
    icon: PropTypes.string,
};

Subtitle.defaultProps = {
    text: '',
    className: '',
    size: '',
    style: '',
    icon: null,
};

export default Subtitle;
